var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "iFooter"
  }, [_c('footer', [_c('div', {
    staticClass: "footer__logo"
  }, [_c('button', {
    on: {
      "click": function click($event) {
        return _vm.movePage('/introduction/main');
      }
    }
  }, [_vm._v("QPICK")])]), _c('div', {
    staticClass: "footer__info"
  }, [_vm._m(0), _c('p', {
    staticClass: "footer__cscenter"
  }, [_c('span', [_vm._v(" 고객지원: "), _c('button', {
    staticClass: "ga_f_19",
    on: {
      "click": function click($event) {
        return _vm.beforeItemEvent(_vm.inquiryMenu);
      }
    }
  }, [_c('em', [_vm._v("1:1 문의 게시판")])])]), _c('span', [_vm._v(" 10:00~18:00 (Break 12:30~13:30) 주말 및 공휴일 휴무")])]), _c('p', {
    staticClass: "footer__copyright"
  }, [_vm._v("Copyright(C) Qpick. All Rights Reserved")])]), _c('div', {
    staticClass: "footer__link"
  }, [_c('ul', [_c('li', [_c('button', {
    on: {
      "click": _vm.openCompany
    }
  }, [_vm._v("회사소개")])]), _c('li', [_c('button', {
    on: {
      "click": function click($event) {
        return _vm.movePage('/introduction/terms?type=use');
      }
    }
  }, [_vm._v(" 이용약관 ")])]), _c('li', [_c('button', {
    on: {
      "click": function click($event) {
        return _vm.movePage('/introduction/terms?type=privacy');
      }
    }
  }, [_c('strong', [_vm._v("개인정보 처리방침")])])])])])])]);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', {
    staticClass: "footer__desc"
  }, [_c('span', [_vm._v("(주)커넥트웨이브")]), _c('span', [_vm._v("대표: 이건수")]), _c('span', [_vm._v("개인정보 보호책임자: 백승준")]), _c('span', [_vm._v("사업자번호: 117-81-40065")]), _c('span', [_vm._v(" 통신판매 신고번호: 제2024-서울금천-0848호, 부가통신사업: 제003081호 ")]), _c('span', [_vm._v(" 주소: 서울특별시 금천구 벚꽃로 298, 17층 (가산동, 대륭포스트타워6차) ")])]);
}]

export { render, staticRenderFns }