<template>
  <div class="iFooter">
    <footer>
      <div class="footer__logo">
        <button @click="movePage('/introduction/main')">QPICK</button>
      </div>
      <div class="footer__info">
        <p class="footer__desc">
          <span>(주)커넥트웨이브</span>
          <span>대표: 이건수</span>
          <span>개인정보 보호책임자: 백승준</span>
          <span>사업자번호: 117-81-40065</span>
          <span>
            통신판매 신고번호: 제2024-서울금천-0848호, 부가통신사업: 제003081호
          </span>
          <span>
            주소: 서울특별시 금천구 벚꽃로 298, 17층 (가산동, 대륭포스트타워6차)
          </span>
        </p>
        <p class="footer__cscenter">
          <span>
            고객지원:
            <button class="ga_f_19" @click="beforeItemEvent(inquiryMenu)">
              <em>1:1 문의 게시판</em>
            </button>
          </span>
          <span> 10:00~18:00 (Break 12:30~13:30) 주말 및 공휴일 휴무</span>
        </p>
        <p class="footer__copyright">Copyright(C) Qpick. All Rights Reserved</p>
      </div>
      <div class="footer__link">
        <ul>
          <li>
            <button @click="openCompany">회사소개</button>
          </li>
          <li>
            <button @click="movePage('/introduction/terms?type=use')">
              이용약관
            </button>
          </li>
          <li>
            <button @click="movePage('/introduction/terms?type=privacy')">
              <strong>개인정보 처리방침</strong>
            </button>
          </li>
        </ul>
      </div>
    </footer>
  </div>
</template>

<script>
import {createNamespacedHelpers} from "vuex";

const ModuleIntroCommon = createNamespacedHelpers("ModuleIntroCommon");
const ModuleAuth = createNamespacedHelpers("ModuleAuth");
const ModuleAlert = createNamespacedHelpers("ModuleAlert");

export default {
  data() {
    return {
      callback: null
    };
  },
  computed: {
    inquiryMenu() {
      return this.getMenuList[2].children[4];
    },
    ...ModuleIntroCommon.mapGetters(["getMenuList"]),
    ...ModuleAuth.mapGetters(["getLoginStatus"])
  },
  methods: {
    beforeItemEvent(data) {
      if (data.id === 407 && !this.getLoginStatus) {
        this.callback = data.clickTarget;
        this.setAlert({
          isVisible: true,
          message: "1:1문의는 로그인 후 이용할 수 있습니다.",
          event: "login"
        });
      } else {
        this.openPage("/inquiry");
      }
    },
    // 페이지 이동
    movePage(path) {
      this.$router.push(path);
    },
    // 페이지 오픈
    openPage(path) {
      window.open(process.env.VUE_APP_EXTERNAL_qpick_help_URL + path);
    },
    // 회사소개 오픈
    openCompany() {
      window.open("https://www.koreacenter.com/");
    },
    ...ModuleAlert.mapMutations(["setAlert"])
  },
  created() {
    this.$EventBus.$on("alertCancel", event => {
      if (event === "login" && this.callback) {
        this.$router.push(
          "/accounts/login?callback=" + encodeURIComponent(this.callback)
        );
      }
    });
  },
  mounted() {}
};
</script>

<style lang="scss" scoped>
div,
button {
  font-family: "Pretendard", "Malgun Gothic", sans-serif;
  color: #1c1e23;
  line-height: 1.25;
}
.iFooter {
  background-color: #171717;
  padding: 80px 0;

  footer {
    position: relative;
    display: flex;
    max-width: 1648px;
    margin: 0 auto;
    padding: 0 24px;
    box-sizing: border-box;
  }
}
.footer__logo button {
  display: block;
  width: 124px;
  height: 40px;
  background-image: url(~@/assets/images/intro/bi_footer.svg);
  background-repeat: no-repeat;
  text-indent: -9999px;
}
.footer__info {
  color: #a3a3a3;

  em {
    color: #e5e5e5;
  }
}
.footer__desc span {
  margin-right: 8px;
}
.footer__cscenter {
  margin-top: 16px;
}
.footer__cscenter button {
  vertical-align: top;
}
.footer__copyright {
  margin-top: 40px;
  color: #737373;
}
.footer__link ul {
  display: flex;
  column-gap: 24px;
}
.footer__link li button {
  height: 22px;
  box-sizing: border-box;
  color: #e5e5e5;
}
@media (min-width: 1024px) {
  .iFooter footer {
    column-gap: 64px;
    font-size: 14px;
  }
  .footer__desc span:nth-child(6)::before {
    content: "\A";
    white-space: pre;
  }
  .footer__link li button:hover {
    border-bottom: 1px solid #e5e5e5;
  }
}
@media (max-width: 1023px) and (min-width: 768px) {
  .iFooter footer {
    column-gap: 40px;
    font-size: 13px;
  }
  .footer__desc span:nth-child(5)::before {
    content: "\A";
    white-space: pre;
  }
}
@media (min-width: 768px) {
  .footer__link {
    position: absolute;
    right: 24px;
    bottom: 0;
  }
}
@media (max-width: 767px) {
  .iFooter {
    padding: 56px 0;

    footer {
      padding: 0 16px;
      flex-direction: column;
      row-gap: 32px;
      font-size: 13px;
    }
  }
  .footer__desc span:nth-child(2)::before,
  .footer__desc span:nth-child(4)::before,
  .footer__desc span:nth-child(5)::before,
  .footer__desc span:nth-child(6)::before {
    content: "\A";
    white-space: pre;
  }
  .footer__link {
    margin-top: -8px;
  }
  .footer__cscenter span {
    display: block;
  }
}
</style>
